var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"dark":"","color":_vm.mode == 'create' ? 'red' : _vm.mode == 'edit' ? 'green' : 'primary'}},[_c('v-toolbar-title',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.title)+" "),(_vm.record.company)?_c('small',{staticClass:"d-block caption"},[_vm._v(_vm._s(_vm.record.company.name))]):_vm._e()]),(_vm.status)?_c('v-chip',{staticClass:"mr-2",attrs:{"outlined":"","label":""}},[_vm._v(_vm._s(_vm.status))]):_vm._e(),(_vm.$route.params.mode == 'view' && _vm.record.company)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.previous()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-chevron-left")])]}}],null,false,586703038)},[_c('span',[_vm._v("Previous")])])],1):_vm._e(),(_vm.$route.params.mode == 'view' && _vm.record.company)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.next()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-chevron-right")])]}}],null,false,1848224229)},[_c('span',[_vm._v("Next")])])],1):_vm._e(),_c('v-spacer'),(
        _vm.$route.params.mode == 'view' &&
        !(!_vm.record.tmp_assignedprepayment && _vm.record.tmp_prepayments && _vm.record.amountdue) &&
        (_vm.$route.params.recordtype == 'customerpayment' ||
          _vm.$route.params.recordtype == 'customer' ||
          _vm.$route.params.recordtype == 'delivery' ||
          _vm.record.pdfform)
      )?_c('v-overflow-btn',{staticClass:"mt-5",staticStyle:{"min-width":"250px"},attrs:{"flat":"","items":_vm.quickemails,"label":"Quick Email","segmented":"","dense":"","menu-props":{ maxHeight: 'unset' }}}):_vm._e(),_c('v-spacer'),(
        _vm.record.quantitycommitted > 0 &&
        _vm.$route.params.mode == 'view' &&
        [
          'open',
          'partlybilled',
          'partlyfulfilled',
          'fulfilled',
          'partlybilled' ].includes(_vm.record.status._id)
      )?_c('v-btn',{attrs:{"icon":"","disabled":!_vm.hasAccess(_vm.record.collection, _vm.record.recordtype, 'edit')},on:{"click":function($event){return _vm.readyToPack()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-package-up")])]}}],null,false,2030279185)},[_c('span',[_vm._v("Ready to Pack")])])],1):_vm._e(),(
        _vm.record.status &&
        [
          'salesorder',
          'inventorytransfer',
          'stockrequest',
          'returnrequest',
          'stockcheck',
          'delivery' ].includes(_vm.record.recordtype) &&
        ['pendingapproval'].includes(_vm.record.status._id) &&
        _vm.$route.params.mode == 'view'
      )?_c('v-btn',{attrs:{"icon":"","disabled":!_vm.hasAccess(_vm.record.collection, _vm.record.recordtype, 'edit')},on:{"click":function($event){return _vm.approveOrder()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-check-outline")])]}}],null,false,3059899176)},[_c('span',[_vm._v("Approve")])])],1):_vm._e(),(
        _vm.record.status &&
        [
          'open',
          'partlybilled',
          'partlyfulfilled',
          'fulfilled',
          'partlybilled' ].includes(_vm.record.status._id) &&
        _vm.record.recordtype == 'salesorder' &&
        _vm.$route.params.mode == 'view'
      )?_c('v-btn',{attrs:{"icon":"","disabled":!_vm.hasAccess(_vm.record.collection, 'invoice', 'create')},on:{"click":function($event){return _vm.invoice()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-receipt")])]}}],null,false,1623795935)},[_c('span',[_vm._v("Invoice")])])],1):_vm._e(),(
        _vm.record.status &&
        [
          'open',
          'partlybilled',
          'partlyfulfilled',
          'fulfilled',
          'partlybilled' ].includes(_vm.record.status._id) &&
        _vm.record.recordtype == 'salesorder' &&
        _vm.$route.params.mode == 'view' &&
        _vm.record.quantitybackorder
      )?_c('v-btn',{attrs:{"icon":"","disabled":!_vm.hasAccess(_vm.record.collection, 'purchaseorder', 'create')},on:{"click":function($event){return _vm.purchaseorder()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-file-document-outline")])]}}],null,false,1496859658)},[_c('span',[_vm._v("Purchase Order")])])],1):_vm._e(),(_vm.record.recordtype == 'invoice' && _vm.$route.params.mode == 'view')?_c('v-btn',{attrs:{"icon":"","disabled":!_vm.hasAccess(_vm.record.collection, 'returnrequest', 'create')},on:{"click":function($event){return _vm.returnrequest()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-file-document-outline")])]}}],null,false,1496859658)},[_c('span',[_vm._v("Return Request")])])],1):_vm._e(),(
        (['estimate', 'warranty'].includes(_vm.record.recordtype) ||
          (['purchaseorder'].includes(_vm.record.recordtype) &&
            _vm.record.customer &&
            ['company'].includes(_vm.record.customer.recordtype))) &&
        _vm.$route.params.mode == 'view'
      )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.createsalesorder()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-file-document")])]}}],null,false,1109489799)},[_c('span',[_vm._v("Create Sales Order")])])],1):_vm._e(),(
        _vm.record.recordtype == 'inventoryitem' && _vm.$route.params.mode == 'view'
      )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-printer")])]}}],null,true)},[_c('span',[_vm._v("Print")])])],1)]}}],null,false,1918691514)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.pdf('pdfinventoryitem', '', _vm.$store.state.user.location)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-book-marker-outline")])],1),_c('v-list-item-title',[_vm._v("Print Information")])],1),_c('v-list-item',{attrs:{"disabled":_vm.$store.state.user.role._id == 'role_peddersuk'},on:{"click":function($event){return _vm.printLabel()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-barcode-scan")])],1),_c('v-list-item-title',[_vm._v("Print Label")])],1)],1)],1):_vm._e(),(
        ['invoice', 'returnrequest'].includes(_vm.record.recordtype) &&
        !['pendingapproval'].includes(
          _vm.record.status ? _vm.record.status._id : _vm.record.status
        ) &&
        _vm.$route.params.mode == 'view' &&
        !['role_warehouse', 'role_warehouse_manager'].includes(
          this.$store.state.user.role._id
        )
      )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.creditmemo()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("assignment_return")])]}}],null,false,1709369576)},[_c('span',[_vm._v("Corrective")])])],1):_vm._e(),(
        ['inventoryadjustment'].includes(_vm.record.recordtype) &&
        _vm.$route.params.mode == 'view'
      )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.vendorcreditmemo()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("assignment_return")])]}}],null,false,1709369576)},[_c('span',[_vm._v("Vendor Credit Memo")])])],1):_vm._e(),(_vm.record.recordtype == 'creditmemo' && _vm.$route.params.mode == 'view')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.returnauthorization()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("assignment_return")])]}}],null,false,1709369576)},[_c('span',[_vm._v("Return Authorization")])])],1):_vm._e(),(
        !_vm.record.closed &&
        ['purchaseorder', 'invoice'].includes(_vm.record.recordtype) &&
        _vm.record.customer &&
        ['vendor', 'company'].includes(_vm.record.customer.recordtype) &&
        _vm.$route.params.mode == 'view'
      )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.bill()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-receipt")])]}}],null,false,1623795935)},[_c('span',[_vm._v("Bill")])])],1):_vm._e(),(
        !_vm.record.closed &&
        ['itemfulfillment', 'invoice', 'purchaseorder'].includes(
          _vm.record.recordtype
        ) &&
        _vm.record.customer &&
        ['vendor', 'company'].includes(_vm.record.customer.recordtype) &&
        _vm.$route.params.mode == 'view'
      )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.delivery()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("local_shipping")])]}}],null,false,2216996846)},[_c('span',[_vm._v("Make Delivery")])])],1):_vm._e(),(
        !_vm.record.closed &&
        ['released'].includes(
          _vm.record.status ? _vm.record.status._id || _vm.record.status : _vm.record.status
        ) &&
        ((_vm.record.recordtype == 'inventorytransfer' &&
          !_vm.record.quantityreceived &&
          _vm.record.quantity < 0) ||
          _vm.record.recordtype == 'stockrequest') &&
        _vm.$route.params.mode == 'view'
      )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.inventorytransfer()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-transfer")])]}}],null,false,2874610824)},[_c('span',[_vm._v("Inventory Transfer")])])],1):_vm._e(),(_vm.record.recordtype == 'stockcheck' && _vm.$route.params.mode == 'view')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.binTransfer()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-transfer")])]}}],null,false,2874610824)},[_c('span',[_vm._v("Bin Transfer")])])],1):_vm._e(),(
        (_vm.record.recordtype == 'returnauthorization' ||
          (_vm.record.recordtype == 'salesorder' && _vm.reqreturntovendor)) &&
        _vm.record.location.location &&
        _vm.$route.params.mode == 'view'
      )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.inventoryadjustment()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-transfer")])]}}],null,false,2874610824)},[_c('span',[_vm._v("Return to Vendor")])])],1):_vm._e(),(
        !_vm.record.closed &&
        _vm.record.recordtype == 'bill' &&
        _vm.$route.params.mode == 'view'
      )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.receipt()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-package-down")])]}}],null,false,3045246694)},[_c('span',[_vm._v("Item Receipt")])])],1):_vm._e(),(_vm.$route.params.mode != 'view')?_c('v-btn',{attrs:{"icon":"","disabled":_vm.record.alert ||
        (['report', 'query'].includes(_vm.record.recordtype) &&
          ((_vm.record.employee &&
            (_vm.record.employee._id || _vm.record.employee) !=
              _vm.$store.state.user._id) ||
            (!_vm.record.employee && _vm.record.created))) ||
        !_vm.hasAccess(_vm.record.collection, _vm.record.recordtype, 'edit') ||
        _vm.baselinkerArchived},on:{"click":function($event){return _vm.saveRecord()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-content-save")])]}}],null,false,3074395270)},[_c('span',[_vm._v("Save")])])],1):_vm._e(),(
        _vm.record.pdfform &&
        _vm.record.collection == 'transactions' &&
        [
          'invoice',
          'estimate',
          'salesorder',
          'customerpayment',
          'purchaseorder',
          'creditmemo',
          'prepayment' ].includes(_vm.record.recordtype) &&
        _vm.saveAndEmailBtn &&
        _vm.$route.params.mode != 'view'
      )?_c('v-btn',{attrs:{"icon":"","disabled":!_vm.hasAccess(_vm.record.collection, _vm.record.recordtype, 'edit')},on:{"click":function($event){return _vm.saveAndEmail()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-content-save-move")])]}}],null,false,2666290362)},[_c('span',[_vm._v("Save and Email")])])],1):_vm._e(),(
        _vm.record.pdfform &&
        _vm.$route.params.mode != 'view' &&
        _vm.record.collection != 'users'
      )?_c('v-btn',{attrs:{"icon":"","disabled":!_vm.hasAccess(_vm.record.collection, _vm.record.recordtype, 'edit')},on:{"click":function($event){return _vm.saveAndPrint()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-cloud-print-outline")])]}}],null,false,1213997571)},[_c('span',[_vm._v("Save and Print")])])],1):_vm._e(),(_vm.$route.params.mode != 'view')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.cancelRecord()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-cancel")])]}}],null,false,2359532037)},[_c('span',[_vm._v("Cancel")])])],1):_vm._e(),(
        _vm.record.pdfform &&
        _vm.$route.params.mode == 'view' &&
        _vm.record.collection != 'users' &&
        !['invoice', 'creditmemo'].includes(_vm.record.recordtype)
      )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.pdf(
          _vm.record.status && _vm.record.status._id == 'readytopack'
            ? 'pdfpickingticket'
            : null
        )}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-printer")])]}}],null,false,4240208741)},[_c('span',[_vm._v("Print")])])],1):(
        _vm.record.pdfform &&
        _vm.$route.params.mode == 'view' &&
        _vm.record.collection != 'users'
      )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-printer")])]}}],null,true)},[_c('span',[_vm._v("Print")])])],1)]}}])},[_c('v-list',[(
            _vm.record.pdfform &&
            _vm.$route.params.mode == 'view' &&
            _vm.record.collection != 'users'
          )?_c('v-list-item',{on:{"click":function($event){return _vm.pdf(
              _vm.record.status && _vm.record.status._id == 'readytopack'
                ? 'pdfpickingticket'
                : null
            )}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-text-box-outline")])],1),_c('v-list-item-title',[_vm._v("Print Document")])],1):_vm._e(),(
            ['invoice'].includes(_vm.record.recordtype) &&
            _vm.$route.params.mode == 'view' &&
            _vm.record.collection == 'transactions'
          )?_c('v-list-item',{attrs:{"disabled":!(
              _vm.record.files ||
              _vm.record.file ||
              _vm.record.file2 ||
              (_vm.record.trackingnumbers && _vm.record.trackingnumbers.length)
            )},on:{"click":function($event){return _vm.pdf('', '', '', 'attach')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-document-plus-outline")])],1),_c('v-list-item-title',[_vm._v("Print Document + attachments")])],1):_vm._e(),(
            ['invoice', 'creditmemo'].includes(_vm.record.recordtype) &&
            _vm.$route.params.mode == 'view' &&
            _vm.record.collection != 'users' &&
            ['role_accounting', 'role_sales'].includes(
              this.$store.state.user.role._id
            )
          )?_c('v-list-item',{on:{"click":function($event){return _vm.pdf('', true)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-document-refresh")])],1),_c('v-list-item-title',[_vm._v("Print Duplicate")])],1):_vm._e(),(
            _vm.record.altpdfform &&
            _vm.$route.params.mode == 'view' &&
            _vm.record.collection != 'users'
          )?_c('v-list-item',{on:{"click":function($event){return _vm.pdf(_vm.record.altpdfform._id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(">mdi-file-document-check-outline")])],1),_c('v-list-item-title',[_vm._v("Print "+_vm._s(_vm.record.altpdfform.name))])],1):_vm._e()],1)],1):_vm._e(),(
        (_vm.record.amountdue ||
          ['customer', 'company', 'salesorder'].includes(
            _vm.record.recordtype
          )) &&
        _vm.$route.params.mode == 'view'
      )?_c('v-btn',{attrs:{"icon":"","disabled":!_vm.hasAccess(_vm.record.collection, 'customerpayment', 'create')},on:{"click":function($event){return _vm.payment()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-account-cash")])]}}],null,false,3664077462)},[_c('span',[_vm._v("Make Payment")])])],1):_vm._e(),(
        (_vm.record.amountdue || ['salesorder'].includes(_vm.record.recordtype)) &&
        _vm.$route.params.mode == 'view'
      )?_c('v-btn',{attrs:{"icon":"","disabled":!_vm.hasAccess(_vm.record.collection, 'prepaymentinvoice', 'create')},on:{"click":function($event){return _vm.prepayment()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-cash-multiple")])]}}],null,false,2728940391)},[_c('span',[_vm._v("Make Prepayment Invoice")])])],1):_vm._e(),(
        (_vm.record.unapplied ||
          ['customer', 'company'].includes(_vm.record.recordtype)) &&
        _vm.$route.params.mode == 'view'
      )?_c('v-btn',{attrs:{"icon":"","disabled":!_vm.hasAccess(_vm.record.collection, 'customerrefund', 'create')},on:{"click":function($event){return _vm.refund()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-cash-refund")])]}}],null,false,1880016761)},[_c('span',[_vm._v("Make Refund")])])],1):_vm._e(),(
        ['customer', 'company'].includes(_vm.record.recordtype) &&
        _vm.$route.params.mode == 'view'
      )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.salesorder()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-file-document")])]}}],null,false,1109489799)},[_c('span',[_vm._v("Create Sales Order")])])],1):_vm._e(),(
        ['customer', 'company'].includes(_vm.record.recordtype) &&
        _vm.$route.params.mode == 'view'
      )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.estimate()}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-file-document-outline")])]}}],null,false,1496859658)},[_c('span',[_vm._v("Create Estimate")])])],1):_vm._e(),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":"","icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[(_vm.$route.params.id && _vm.$route.params.mode == 'view')?_c('v-list-item',{attrs:{"disabled":!_vm.hasAccess(_vm.record.collection, _vm.record.recordtype, 'edit')},on:{"click":function($event){return _vm.editRecord()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-title',[_vm._v("Edit")])],1):_vm._e(),(
            _vm.$route.params.id &&
            (_vm.$route.params.mode == 'view' ||
              (_vm.$route.params.mode == 'edit' &&
                _vm.record.collection == 'queries')) &&
            [
              'items',
              'transactions',
              'users',
              'queries',
              'emails',
              'lists',
              'deliveries' ].includes(_vm.record.collection)
          )?_c('v-list-item',{attrs:{"disabled":!_vm.hasAccess(_vm.record.collection, _vm.record.recordtype, 'create')},on:{"click":function($event){return _vm.copy()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-list-item-title',[_vm._v("Copy")])],1):_vm._e(),(
            _vm.$route.params.id &&
            _vm.$route.params.mode == 'view' &&
            ['items', 'transactions', 'users'].includes(_vm.record.collection)
          )?_c('v-list-item',{attrs:{"disabled":!_vm.hasAccess(_vm.record.collection, _vm.record.recordtype, 'create')},on:{"click":function($event){return _vm.newrecord()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-plus-box-outline")])],1),_c('v-list-item-title',[_vm._v("New")])],1):_vm._e(),(
            _vm.$route.params.id &&
            _vm.$route.params.mode != 'view' &&
            [
              'salesorder',
              'purchaseorder',
              'returnrequest',
              'transportclaim' ].includes(_vm.$route.params.recordtype)
          )?_c('v-list-item',{attrs:{"disabled":!_vm.hasAccess(_vm.record.collection, _vm.record.recordtype, 'edit')},on:{"click":function($event){return _vm.closeOrder()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.record.closed ? "Open" : "Close"))])],1):_vm._e(),(
            _vm.$route.params.id &&
            _vm.$route.params.mode != 'view' &&
            _vm.record.collection != 'items'
          )?_c('v-list-item',{attrs:{"disabled":(_vm.record.recordtype == 'report' &&
              ((_vm.record.employee &&
                _vm.record.employee != _vm.$store.state.user._id) ||
                !_vm.record.employee)) ||
            !_vm.hasAccess(_vm.record.collection, _vm.record.recordtype, 'delete')},on:{"click":function($event){return _vm.deleteRecord()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1),_c('v-list-item-title',[_vm._v("Delete")])],1):_vm._e(),(
            _vm.record.status &&
            [
              'salesorder',
              'inventorytransfer',
              'stockrequest',
              'returnrequest' ].includes(_vm.record.recordtype) &&
            ['open'].includes(_vm.record.status._id) &&
            _vm.$route.params.mode == 'view'
          )?_c('v-list-item',{attrs:{"disabled":!_vm.hasAccess(_vm.record.collection, _vm.record.recordtype, 'edit')},on:{"click":function($event){return _vm.approveOrder()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clock-alert-outline")])],1),_c('v-list-item-title',[_vm._v("Pending Approval")])],1):_vm._e()],1)],1),_c('v-snackbar',{attrs:{"color":"red","timeout":6000,"top":"","center":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.msg.show = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.msg.show),callback:function ($$v) {_vm.$set(_vm.msg, "show", $$v)},expression:"msg.show"}},[_vm._v(_vm._s(_vm.msg.message)+" ")])],1),_c('v-overlay',{attrs:{"value":_vm.recalc}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }