<template>
  <div>
    <div
      v-if="formSettings && formSettings.tool && record._id"
      style="width: 100%; position: relative"
    >
      <component
        :is="formSettings.tool.tool"
        :record.sync="record"
        :selected.sync="selected"
        :id="formSettings.tool.tool"
        @emit="catchEmit"
        v-if="
          formSettings.tool.status && record.status
            ? formSettings.tool.status.includes(record.status._id)
            : true && formSettings.tool.mode
            ? formSettings.tool.mode.includes($route.params.mode)
            : true
        "
      />
    </div>
    <v-overlay v-if="query && query.recordtype == 'report'" :value="loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col>
        <v-tabs dark :class="tabs.other ? 'hidetablist' : ''">
          <v-tab
            v-for="(location_value, location, index) in tabs"
            :key="index"
            :class="location == 'other' ? 'd-none' : ''"
            v-model="active"
          >
            {{ location }}
          </v-tab>

          <v-tab-item
            v-for="(location_value, location, index) in tabs"
            :key="index"
          >
            <v-tabs :vertical="!$vuetify.breakpoint.mobile">
              <v-tab
                v-for="(value, key) in location_value"
                :key="key"
                :class="key == 'other' ? 'd-none' : ''"
              >
                <v-badge
                  v-if="value"
                  tile
                  left
                  inline
                  :content="value.length"
                  :value="value.length"
                  color="#363636"
                  >{{ key }}
                </v-badge>
              </v-tab>
              <v-tab-item v-for="(value, key) in location_value" :key="key">
                <v-data-table
                  :headers="
                    headers.filter(
                      (f) =>
                        !f.parentfield && !(groupby || '').includes(f.value)
                    )
                  "
                  :items="value"
                  :options.sync="options"
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  item-key="_id"
                  :footer-props="{ itemsPerPageOptions: rows }"
                  :single-expand="singleExpand"
                  :expanded.sync="expanded"
                  :custom-filter="filter"
                  :search="search"
                  :custom-sort="sort"
                  :hide-default-footer="hidefooter"
                  v-model="selected"
                  :show-select="show"
                  :group-by="groupby"
                  :custom-group="customGroup"
                >
                  <template
                    v-slot:[`group.header`]="{ group, toggle, isOpen, items }"
                  >
                    <th
                      :colspan="
                        headers.filter(
                          (f) =>
                            !f.parentfield &&
                            fields[f.field] &&
                            !['field-currency', 'field-int'].includes(
                              fields[f.field].setup.type
                            )
                        ).length
                      "
                    >
                      <v-btn
                        @click="toggle"
                        small
                        icon
                        :ref="group"
                        :data-open="isOpen"
                      >
                        <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                        <v-icon v-else>mdi-chevron-down</v-icon>
                      </v-btn>

                      <!-- <v-icon @click="toggle">{{
                        !isOpen ? "mdi-playlist-plus" : "mdi-playlist-remove"
                      }}</v-icon> -->
                      {{ group }}
                    </th>
                    <th
                      v-for="col of headers.filter(
                        (f) =>
                          !f.parentfield &&
                          fields[f.field] &&
                          ['field-currency', 'field-int'].includes(
                            fields[f.field].setup.type
                          )
                      )"
                      :key="col.value"
                    >
                      {{
                        items
                          .reduce((t, v) => {
                            t += v[col.value] || 0;

                            return t;
                          }, 0)
                          .toFixed(
                            fields[col.field] &&
                              fields[col.field].setup.type == "field-int"
                              ? 0
                              : 2
                          )
                      }}
                    </th>
                  </template>
                  <template v-slot:headers="props">
                    <tableheaders
                      :props="props"
                      :items="items"
                      :options="options"
                    />
                  </template>
                  <template v-slot:item="props">
                    <tablerows
                      v-if="!props.deleted"
                      :props="props"
                      :headers="
                        headers.filter(
                          (f) => !(groupby || '').includes(f.value)
                        )
                      "
                      :options="options"
                      :fields="fields"
                      :lazy="lazy"
                    />
                  </template>

                  <template v-slot:expanded-item="props">
                    <editrecord
                      v-if="options.subrecords"
                      :options="options"
                      :record="props.item"
                      :parent.sync="record"
                      :showselect="show"
                      :index="
                        items
                          .map(function (x) {
                            return x._id;
                          })
                          .indexOf(props.item._id)
                      "
                      :columns="headers"
                      :key="props.item._id"
                      @emit="catchEmit($event, props)"
                    />
                  </template>
                  <template
                    v-slot:top
                    v-if="
                      $route.params.mode == 'view' &&
                      ['salesorder', 'purchaseorder'].includes(
                        $route.params.recordtype
                      )
                    "
                  >
                    <v-row style="position: relative; text-align: right">
                      <v-col>
                        <v-overflow-btn
                          :disabled="!selected.length"
                          v-if="
                            $route.params.mode == 'view' &&
                            ['transactionlines'].includes(settings._id) &&
                            ['salesorder', 'purchaseorder'].includes(
                              $route.params.recordtype
                            )
                          "
                          style="min-width: 250px"
                          flat
                          class="mt-0"
                          :items="quickemails"
                          label="Quick Email/Action"
                          segmented
                          dense
                          menu-props="{ maxHeight: unset }"
                        >
                        </v-overflow-btn>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:footer>
                    <div style="position: relative; text-align: right">
                      <v-fab-transition
                        v-if="options.subrecords && options.add"
                      >
                        <v-btn
                          small
                          absolute
                          top
                          left
                          fab
                          style="top: 15px"
                          @click="addrow()"
                        >
                          <v-icon>add</v-icon>
                        </v-btn>
                      </v-fab-transition>
                      <v-fab-transition
                        v-if="
                          options.subrecords &&
                          selected.length &&
                          ['edit', 'create'].includes($route.params.mode)
                        "
                      >
                        <v-btn
                          small
                          absolute
                          top
                          left
                          fab
                          style="left: 80px; top: 15px"
                          @click="deleterows()"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </v-fab-transition>

                      <v-expand-transition>
                        <div>
                          <v-container fluid>
                            <v-col
                              v-if="
                                query &&
                                query.setup.filters &&
                                query.setup.filters.some((f) => f.show)
                              "
                            >
                              <v-row class="pb-6"
                                ><v-subheader>Filters</v-subheader></v-row
                              >
                              <div
                                v-for="(
                                  column, index
                                ) of query.setup.columns.filter(
                                  (col) => col.field == 'date'
                                )"
                                :key="index"
                              >
                                <v-row v-if="column.function">
                                  <v-col class="pa-0">
                                    <v-subheader>
                                      {{
                                        column.name || fields[column.field].name
                                      }}
                                      Settings</v-subheader
                                    >
                                  </v-col>
                                  <v-col class="col-3 py-1">
                                    <v-select
                                      :items="dateFunc"
                                      v-model="column.function"
                                      label="Date Unit"
                                      item-text="name"
                                      item-value="_id"
                                      outlined
                                      dense
                                      @change="dataUnitChange($event)"
                                    ></v-select>
                                  </v-col>
                                  <v-col class="col-3 py-1">
                                    <v-select
                                      :items="dateSummary"
                                      v-model="column.summary"
                                      label="Date Summary"
                                      item-text="name"
                                      item-value="_id"
                                      outlined
                                      dense
                                      @change="dataUnitChange($event)"
                                    ></v-select>
                                  </v-col>
                                </v-row>
                              </div>
                              <div
                                v-for="(filter, index) of query.setup.filters"
                                :key="index"
                              >
                                <v-row v-if="filter.show">
                                  <v-col class="pa-0">
                                    <v-subheader>{{
                                      filter.name ||
                                      (fields[filter.field]
                                        ? fields[filter.field].name
                                        : "")
                                    }}</v-subheader>
                                  </v-col>
                                  <v-col class="col-3 py-1">
                                    <v-select
                                      :items="operators"
                                      v-model="filter.operator"
                                      label="Operator"
                                      item-text="name"
                                      item-value="_id"
                                      outlined
                                      dense
                                    ></v-select>
                                  </v-col>
                                  <v-col class="py-1">
                                    <v-component
                                      v-if="filter.fieldSettings"
                                      :is="fields[filter.field].setup.type"
                                      :record="filter"
                                      :value="filterDecode(filter)"
                                      :fieldSettings="filter.fieldSettings"
                                      :formSettings="filter"
                                      @emit="catchEmitFilter($event, filter)"
                                    />
                                  </v-col>
                                </v-row>
                              </div>
                              <v-btn
                                @click="action()"
                                block
                                tile
                                color="primary"
                                class="mt-2 mb-2"
                              >
                                <v-icon left>mdi-cloud-download</v-icon>
                                Generate/Refresh
                              </v-btn>
                            </v-col>
                            <v-col
                              v-if="
                                selected.length &&
                                ['deliverycheck'].includes(record.recordtype)
                              "
                            >
                              <v-btn
                                @click="printLabel()"
                                block
                                tile
                                color="primary"
                                class="mt-2 mb-2"
                                >Print Selected Label</v-btn
                              >
                            </v-col>
                            <v-row
                              v-if="
                                selected.length &&
                                ['purchaseorder'].includes(record.recordtype) &&
                                ['edit'].includes(record.mode)
                              "
                            >
                              <v-spacer></v-spacer>
                              <v-spacer></v-spacer>
                              <v-col>
                                <v-menu
                                  v-model="etdmenu"
                                  :close-on-content-click="true"
                                  transition="scale-transition"
                                  offset-y
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      tile
                                      color="primary"
                                      class="mt-2 mb-2"
                                      >Set ETD for selected</v-btn
                                    >
                                  </template>
                                  <v-date-picker
                                    v-model="etd"
                                    @change="setETD"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                            <v-col
                              v-if="
                                selected.length &&
                                ['inventorylocations'].includes(settings._id)
                              "
                            >
                              <v-btn
                                @click="binTransfer()"
                                block
                                tile
                                color="primary"
                                class="mt-2 mb-2"
                                >Bin transfer</v-btn
                              >
                            </v-col>
                            <v-row>
                              <v-spacer></v-spacer>
                              <v-col>
                                <exportdata
                                  :items="value"
                                  :headers="headers"
                                  :name="
                                    record
                                      ? record.name
                                      : query
                                      ? query.name
                                      : null
                                  "
                                />
                              </v-col>
                              <v-col>
                                <v-text-field
                                  dense
                                  v-model="search"
                                  append-icon="search"
                                  label="Search"
                                  single-line
                                  hide-details
                                  outlined
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </div>
                      </v-expand-transition>
                    </div>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="msg.show"
      :color="msg.type"
      :timeout="6000"
      top
      absolute
      right
      >{{ msg.message }}</v-snackbar
    >
  </div>
</template>

<script>
/* eslint-disable */
import getHeaders from "./getHeaders";
import getQueryResult from "../../common/methods/getQueryResult";
import editRecord from "./editRecord";
import tableHeaders from "./headers";
import tableRows from "./rows";
import getFields from "../../common/methods/getFields";
import exportData from "./exportData";
import filter from "./filter";
import sort from "./sort";
import decodeBarcode from "./decodeBarcode";
import service from "../../api/index";
import { sendEmail } from "../../common/methods/email";
import saveRecord from "../../common/methods/saveRecord";
export default {
  props: ["record", "query", "settings", "formSettings"],
  data() {
    return {
      active: "other",
      etdmenu: false,
      etd: null,
      grouped: [],
      quickemails: [],
      stop: false,
      options: {
        subrecords: false,
        grouped: false,
        add: false,
      },
      selected: [],
      singleExpand: true,
      expanded: [],
      expand: false,
      items: [],
      tabs: { other: { other: this.items } },
      total: 0,
      loading: true,
      rows: [25, 50, 100, -1],
      headers: [],
      fields: {},
      newrow: false,
      msg: false,
      search: "",
      show: false,
      hidefooter: false,
      lazy: undefined,
      operators: [
        { name: "Equal", _id: "$eq" },
        { name: "Greater Than", _id: "$gt" },
        { name: "Greater Than or Equal", _id: "$gte" },
        { name: "Less Than ", _id: "$lt" },
        { name: "Less Than or Equal", _id: "$lte" },
        { name: "Not Equal", _id: "$ne" },
        { name: "Include", _id: "$in" },
        { name: "Not Include", _id: "$nin" },
        { name: "Contains", _id: "$contains" },
        { name: "Does not Contain", _id: "$notcontain" },
        { name: "Starts With", _id: "$startswith" },
        { name: "Does not Starts With", _id: "$notstartwith" },
        { name: "Exists", _id: "$exists" },
      ],
      dateunit: null,
      dateFunc: [
        { name: "Week", _id: "$week" },
        { name: "Month", _id: "$month" },
        //{ name: "day", _id: "$day" },
        { name: "Quarter", _id: "$quarter" },
        { name: "Year", _id: "$year" },
      ],
      dateSummary: [
        { name: "Timeline", _id: "$timeline" },
        { name: "Group", _id: "$group" },
        //{ name: "day", _id: "$day" },
        { name: "Max", _id: "$max" },
        { name: "Min", _id: "$min" },
      ],
      groupby: null,
    };
  },
  components: {
    editrecord: editRecord,
    tableheaders: tableHeaders,
    tablerows: tableRows,
    exportdata: exportData,
    decodebarcode: decodeBarcode,
  },
  async created() {
    let vthis = this;
    window.onkeydown = function (e) {
      if (e.keyCode == 70 && e.ctrlKey) {
        vthis.lazy = true;
      }
    };

    for (let [key, value] of Object.entries(this.$route.query)) {
      if (this.query && this.query.setup && this.query.setup.filters)
        this.query.setup.filters.forEach((filter) => {
          if (filter.field == key)
            filter.value = ["$in", "$nin"].includes(filter.operator)
              ? [value]
              : value;
        });
    }
    this.fields = await getFields(this, this.record || this.query);
    if (this.query && this.query.setup && this.query.setup.filters)
      for (let filter of this.query.setup.filters) {
        filter.fieldSettings = await this.getField(filter);
      }
    if (
      this.settings &&
      this.settings.recordtype == "subrecords" &&
      this.$route.params.mode != "view"
    ) {
      //this.rows = [-1];
      //this.hidefooter = true;
      this.options.subrecords = true;
      this.options.add = this.settings.setup.add !== false ? true : false;
      this.options.duplicate = this.settings.setup.duplicate;
    }
    if (
      this.settings &&
      [
        "transactionlines",
        "deliveryrecords",
        "stockchecks",
        "contacts",
      ].includes(this.settings._id)
    ) {
      this.rows = [-1];
      this.hidefooter = true;
    }
    this.headers = await getHeaders(
      this.record,
      this.query ||
        Object.assign(this.fields[this.settings._id], this.formSettings),
      this.options
    );
    if (
      this.query &&
      ((this.$route.params.recordtype != "report" && !this.query.setup.used) ||
        !(this.query.setup.filters || []).some((f) => f.show))
    )
      await this.downloadData();

    this.headers = await getHeaders(
      this.record,
      this.query ||
        Object.assign(this.fields[this.settings._id], this.formSettings),
      this.options
    );

    if (this.settings) {
      this.items = this.record[this.settings._id] || [];
      this.tabs = { other: { other: this.items } };
    }

    if (
      (this.record &&
        ((["salesorder", "purchaseorder"].includes(this.record.recordtype) &&
          this.$route.params.mode == "view") ||
          (["creditmemo", "transportclaim"].includes(this.record.recordtype) &&
            this.$route.params.mode == "create"))) ||
      (this.record && this.record.recordtype == "deliverycheck") ||
      (this.record &&
        this.record.collection == "items" &&
        this.$route.params.mode != "view" &&
        this.fields[this.settings._id] &&
        this.fields[this.settings._id].setup.add != false) ||
      (this.record &&
        this.record.recordtype == "purchaseorder" &&
        this.$route.params.mode == "edit")
    )
      this.show = true;
    this.loading = false;
  },
  watch: {
    async "record.bincontrol"(value) {
      this.headers = await getHeaders(
        this.record,
        this.query ||
          Object.assign(
            this.fields[this.settings._id] || {},
            this.formSettings
          ),
        this.options
      );
    },
    async "record.dropship"(value) {
      this.headers = await getHeaders(
        this.record,
        this.query ||
          Object.assign(
            this.fields[this.settings._id] || {},
            this.formSettings
          ),
        this.options
      );
    },
    async selected() {
      if (this.selected.length) {
        let templates = await service(
          "emails",
          {
            action: "find",
            data: [
              {
                recordtype: "emailtemplate",
                hidden: { $ne: true },
                parenttype: { $in: [this.record.recordtype, null, undefined] },
                type: {
                  $in: ["itemline"],
                },
                company: {
                  $in: [
                    this.record.company ? this.record.company._id : null,
                    null,
                  ],
                },
                $or: [
                  {
                    forsources: this.record.source
                      ? this.record.source._id
                      : { $ne: [] },
                  },
                  { forsources: [] },
                  { forsources: { $exists: false } },
                ],
              },
            ],
          },
          false,
          true
        );
        this.quickemails = [];

        templates.map((template) => {
          let emails = this.selected.map((line) => {
            return {
              record: line._id,
              collection: line.collection,
              recordtype: line.recordtype,
              emailtemplate: template._id,
            };
          });
          this.quickemails.push({
            text: template.displayname || template.name,
            callback: () => {
              var response = confirm("Send Email - Are you sure?");
              if (response) {
                sendEmail(this, emails);
                alert("Sending in progress...");
              }
            },
          });
        });

        if (
          this.settings &&
          this.settings._id == "transactionlines" &&
          ["salesorder"].includes(this.$route.params.recordtype)
        ) {
          this.quickemails.push({
            text: "New ETA Request (action - beta)",
            callback: () => {
              var response = confirm("Are you sure?");
              if (response) {
                this.transactionRequest(this.selected);
              }
            },
          });
        }

        if (
          this.settings &&
          this.settings._id == "transactionlines" &&
          this.record &&
          !this.record.fleetorder &&
          ["salesorder"].includes(this.$route.params.recordtype)
        ) {
          this.quickemails.push({
            text: "Auto Unlink PO (action - beta)",
            callback: () => {
              var response = confirm("Are you sure?");
              if (response) {
                this.unlinkPO(this.selected);
              }
            },
          });
        }
      }
    },
    record: {
      handler: async function (after, before) {
        if (this.settings) {
          this.items = this.record[this.settings._id];
          // this.tabs = { other: { other: this.items } };
        }
      },
      deep: true,
    },
    items(value) {
      if (this.settings) {
        this.tabs = { other: { other: value } };
      }
      //if (this.options.itemsPerPage > value.length) this.hidefooter = true;
    },
    "record.box"(value) {
      this.search = value;
    },
  },
  methods: {
    async unlinkPO(selected) {
      await service("transactions", {
        action: "unlinkPO",
        lines: selected.map((s) => s._id),
        order: this.record._id,
      }).then((res) => {
        selected.forEach((s) => {
          s.specialorders = [];
          s.quantityonorder = 0;
        });
      });
    },
    async transactionRequest(selected) {
      for (let s of selected) {
        let tranrequest = {
          name: s._id,
          created: "new",
          recordtype: "transactionrequest",
          collection: "transactions_requests",
          transaction: s.transaction,
          createdfromline: s._id,
        };
        await saveRecord(tranrequest, false, "save", "transactions_requests");
      }
      selected = [];
      alert("Request Sent.");
    },
    closeAll() {
      console.log("closeAll");
      Object.keys(this.$refs).forEach((k) => {
        if (
          this.$refs[k] &&
          this.$refs[k][0].$attrs &&
          this.$refs[k][0].$attrs["data-open"]
        ) {
          this.$refs[k][0].$el.click();
        }
      });
    },
    setETD(v) {
      this.selected.forEach((line) => {
        line.etd = v;
      });
    },
    customGroup(items, groupBy, groupDesc) {
      const key = groupBy[0];

      let groups = items.reduce((rv, x) => {
        const group = x[key] ? x[key].name || x[key] : null;
        if (group) (rv[group] = rv[group] || []).push(x);
        return rv;
      }, {});
      return Object.keys(groups).map((key) => ({
        name: key,
        items: groups[key],
      }));
    },
    dataUnitChange(event) {
      this.dateunit = event;
    },
    async getField(filter) {
      let fields = this.fields;
      if (
        filter.source &&
        fields[filter.source] &&
        fields[filter.source].setup &&
        fields[filter.source].setup.collection
      ) {
        fields = await getFields(this, fields[filter.source]);
      }
      return fields[filter.field];
    },
    filterDecode(filter) {
      if (filter.value == "$today")
        filter.value = new Date().toISOString().substr(0, 10);
      if (filter.value == "$yesterday")
        filter.value = new Date(new Date().setDate(new Date().getDate() - 1))
          .toISOString()
          .substr(0, 10);
      if (filter.value == "$tomorrow")
        filter.value = new Date(new Date().setDate(new Date().getDate() + 1))
          .toISOString()
          .substr(0, 10);

      if (filter.value == "$sameDayLastYear")
        filter.value = new Date(
          new Date(new Date().setYear(new Date().getFullYear() - 1))
        )
          .toISOString()
          .substr(0, 10);
      if (filter.value == "$sameDayYearBeforeLast")
        filter.value = new Date(
          new Date(new Date().setYear(new Date().getFullYear() - 2))
        )
          .toISOString()
          .substr(0, 10);
      if (filter.value == "$sameDayLastMonth")
        filter.value = new Date(
          new Date(new Date().setMonth(new Date().getMonth() - 1))
        )
          .toISOString()
          .substr(0, 10);
      if (filter.value == "$sameDayLastWeek")
        filter.value = new Date(new Date().setDate(new Date().getDate() - 7))
          .toISOString()
          .substr(0, 10);

      if (filter.value == "$startOfLastYear")
        filter.value = new Date(new Date().getFullYear() - 1, 0, 2)
          .toISOString()
          .substr(0, 10);
      if (filter.value == "$startOfLastMonth")
        filter.value = new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          2
        )
          .toISOString()
          .substr(0, 10);
      if (filter.value == "$startOf3MonthsAgo")
        filter.value = new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 3,
          2
        )
          .toISOString()
          .substr(0, 10);
      if (filter.value == "$startOfLastWeek")
        filter.value = new Date(
          new Date().setDate(new Date().getDate() - (6 + new Date().getDay()))
        )
          .toISOString()
          .substr(0, 10);

      if (filter.value == "$startOfThisYear")
        filter.value = new Date(new Date().getFullYear(), 0, 2)
          .toISOString()
          .substr(0, 10);
      if (filter.value == "$startOfThisMonth")
        filter.value = new Date(
          new Date(new Date().setMonth(new Date().getMonth())).setDate(1)
        )
          .toISOString()
          .substr(0, 10);
      if (filter.value == "$startOfThisWeek")
        filter.value = new Date(
          new Date().setDate(new Date().getDate() - (new Date().getDay() - 1))
        )
          .toISOString()
          .substr(0, 10);

      if (filter.value == "$endOfLastMonth")
        filter.value = new Date(new Date().setDate(0))
          .toISOString()
          .substr(0, 10);
      return filter.value;
    },
    async action() {
      this.loading = true;
      await this.downloadData();
      this.headers = await getHeaders(
        this.record,
        this.query ||
          Object.assign(this.fields[this.settings._id], this.formSettings),
        this.options
      );
      this.$emit("emit", { dateunit: this.dateunit });
      this.loading = false;
      if (this.groupby && this.query && this.query.setup.collapse)
        this.closeAll();
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
    filter(value, search, item) {
      if (this.record && this.record.box) {
        if (item.box && item.box.split(",").includes(this.record.box))
          return true;
        else return false;
      } else return filter(value, search, item);
    },
    addrow() {
      if (this.settings) {
        let newrow = {
          collection: this.settings.setup.collection,
          recordtype: this.settings.setup.recordtype,
          parentrecordtype: this.$route.params.recordtype,
        };
        this.headers.forEach((col) => {
          if (col.field) newrow[col.field] = undefined;
        });
        this.expanded = [null];
        let newIndex = false;
        if (!this.items[0] || this.items[this.items.length - 1]._id)
          this.items.push(newrow);
      }
    },
    deleterows() {
      this.selected.forEach((record, row) => {
        let index = this.items.findIndex((item) => item._id == record._id);
        record.deleted = true;
        let multiple = false;
        if (row != this.selected.length - 1) multiple = true;
        this.catchEmit({
          action: "delete",
          index: index,
          record: record,
          multiple: multiple,
        });
      });
      this.selected = [];
    },
    catchEmitFilter(e, props) {
      props.value = e.value;
    },
    catchEmit(e, props) {
      if (
        e.action == "init" ||
        e.action == "save" ||
        (e.action == "delete" && e.record.created != "new")
      ) {
        if (e.index < this.items.length)
          Object.assign(this.items[e.index], e.record);
        else this.items.push(e.record);
      }
      if (e.action == "duplicate") {
        this.items.push(e.record);
      }
      if (e.action == "delete" && e.record.created == "new") {
        this.items.splice(e.index, 1);
      }
      if (e.action != "init") {
        this.expanded = [];
        this.newrow = false;
        if (!e.multiple || e.record.created != "new") {
          this.$emit("emit", {
            field: this.settings._id,
            value: this.items,
            subrecord: e.record,
          });
        }
      }
    },
    async downloadData() {
      if (this.query.recordtype == "report") {
        try {
          await getQueryResult(this.query).then((response) => {
            this.groupby = this.query.setup.groupby || null;
            if (this.groupby && this.query.setup.collapse) this.rows = [-1];
            this.items = response.results;
            this.grouped = [];
            if (response.columns) this.query.setup.columns = response.columns;
            this.query.setup.columns.forEach((col, index) => {
              if (col.resultgroup)
                this.grouped.push(col.value || "column#" + index);
            });
            if (!this.grouped.length) this.grouped = [null, null];
            if (this.grouped.length == 1) this.grouped.push(null);
            if (this.items.length) {
              this.tabs = this.items.reduce((arr, v) => {
                v.groupby = this.groupby;
                let tmp = null;
                this.grouped.forEach((group, index) => {
                  if (index)
                    arr[v[tmp] ? v[tmp].name || v[tmp] : "other"][
                      v[group] ? v[group].name || v[group] : "other"
                    ] =
                      arr[v[tmp] ? v[tmp].name || v[tmp] : "other"][
                        v[group] ? v[group].name || v[group] : "other"
                      ] || [];
                  else {
                    arr[v[group] ? v[group].name || v[group] : "other"] =
                      arr[v[group] ? v[group].name || v[group] : "other"] || {};
                    tmp = group;
                  }
                  if (index + 1 == this.grouped.length) {
                    if (index) {
                      arr[v[tmp] ? v[tmp].name || v[tmp] : "other"][
                        v[group] ? v[group].name || v[group] : "other"
                      ] =
                        arr[v[tmp] ? v[tmp].name || v[tmp] : "other"][
                          v[group] ? v[group].name || v[group] : "other"
                        ] || [];

                      arr[v[tmp] ? v[tmp].name || v[tmp] : "other"][
                        v[group] ? v[group].name || v[group] : "other"
                      ].push(v);
                    } else {
                      arr[v[group] ? v[group].name || v[group] : "other"] =
                        arr[v[group] ? v[group].name || v[group] : "other"] ||
                        [];
                      arr[v[group] ? v[group].name || v[group] : "other"].push(
                        v
                      );
                    }
                  }
                });

                return arr;
              }, {});
            } else this.tabs = { other: { other: this.items } };
            console.log(this.tabs);
            //this.items = [];
          });
        } catch (error) {
          this.msg = { message: `${error.message}`, show: true, type: "error" };
        }
      } else {
        this.stop = false;
        try {
          this.items = [];
          this.tabs = { other: { other: this.items } };
          let { sortBy, sortDesc, page, itemsPerPage } = this.options;

          let sort = { _id: -1 };
          let newresult = [];
          let step = 0;
          let size = 1000;
          let minsize = 100;
          let skip = 0;
          let toimport = [];
          let last = null;
          do {
            const options = [
              { $sort: sort },
              //{ $skip: skip },
              { $limit: step ? size : minsize },
            ];
            skip += step == 0 ? minsize : size;

            //toimport.push(
            await getQueryResult(this.query, options, last).then((response) => {
              this.items.push(...response);
              if (response.length == 0) this.stop = true;
              else last = response[response.length - 1]._id;
            });
            step++;
          } while (this.stop == false);
        } catch (error) {
          this.msg = { message: `${error.message}`, show: true, type: "error" };
        }
      }
      this.$emit("emit", {
        items: this.items,
        headers: this.headers,
      });
    },
    binTransfer() {
      this.$router.push({
        name: "Transactions",
        params: {
          recordtype: "bintransfer",
          mode: "create",
          location: "5e0dbaba9e33df43f0b3a495",
          transaction_bin: this.selected.map((line) => {
            return {
              created: "new",
              item: line.item,
              //quantity: line.quantityawailable * -1,
            };
          }),
        },
      });
    },
    async printLabel() {
      for (let record of this.selected) {
        let qty = (record.quantitytopack || 0) - (record.quantity || 0);
        if (qty != null) {
          let bin = record.bin ? record.bin.split(",") : [""];
          for (let i = 0; i < qty; i++) {
            service("items", {
              action: "toPrint",
              type: "label",
              name: record.item.name,
              delivery: this.record.delivery.name,
              barcode: record.barcode,
              bin: bin[0],
            });
          }
        }
      }
    },
  },
  beforeDestroy() {
    console.log("destroy", this.stop);
    this.stop = true;
  },
};
</script>
<style>
.v-data-table td {
  font-size: 12px !important;
}
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child, .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row), .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:last-child, .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row), .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th,
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td, .v-data-table>.v-data-table__wrapper>table>tbody>tr>th, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>th, .v-data-table>.v-data-table__wrapper>table>thead>tr>td, .v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  padding: 0px 10px;
  border-left: thin solid rgb(0 0 0 / 5%);
}
td:first-child,th:first-child{
  border-left: none!important
}
/* .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
    color: rgba(0, 0, 0, .6);
    background: #e1e1e1;
    font-weight: 400;
} */
.theme--light.v-data-table .v-data-table-header th.sortable[aria-sort="none"] .v-data-table-header__icon {
    display: none;
}

</style>
<style scoped>
.v-tab {
  justify-content: left;
}
span.v-badge >>> .v-badge__wrapper {
  margin-right: 10px !important;
}
.v-tabs.hidetablist >>> .v-tabs-bar {
  display: none;
}
</style>